<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">

          <div class="modal-header">
            <h2>Da li ste sigurni da želite navedeno glavno jelo? / Are you sure you want to change your main course ?</h2>
          </div>
          <div class="modal-body">
            <slot name="body">
              <span></span>
            </slot>
          </div>

          <hr>

          <div class="modal-footer">
            <slot name="footer">
              <button class="modal-default-button" @click="$emit('confirm', true)">
                Da / Yes
              </button>
              <button class="secondary-button" @click="$emit('close')">
                Ne / No
              </button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>


<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 300px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header {
  margin-top: 0;
  color: #42b983;
  display: flex;
  justify-content: center;
  h2 {
    margin-bottom: 0px;
    margin-top: 0px;
    text-align: center;
  }
}

.modal-body {
  margin: 8px auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 263px;

  span {
    font-family: 'Gilroy-Bold', serif;
    text-decoration: underline;
    font-size: 18px;
    line-height: 21px;
    color: #070B4A;
    text-align: center;
  }
}

hr {
  border: 1px solid rgba(0, 0, 0, 0.06);
  width: 112px;
  margin: 18px auto;
}

.modal-footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

button {
  margin: 0;

}

.modal-default-button {
  display: block;

}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}



</style>