<template>
  <div>
    <div class="nav-info">
      <div class="info">
        <div class="meat"><span>Meso:</span><h5>{{ totalMeat }}</h5></div>
        <div class="fish"><span>Riba:</span><h5>{{ totalFish }}</h5></div>
        <div class="vegan"><span>Vegan:</span><h5>{{ totalVegan }}</h5></div>
        <div class="non"><span>Nije odabrano:</span><h5>{{ totalNon }}</h5></div>
      </div>
    </div>
    <div>
      <div class="hall">
        <div v-for="table in tables" :key="table.id" class="table-wrapper">
          <div class="table">
            <h5>{{table.number}}</h5>
            <span class="meat">{{ table.totalMeat }}</span>
            <span class="fish">{{ table.totalFish }}</span>
            <span class="vegan">{{ table.totalVegan }}</span>
          </div>
          <div v-for="chair in table.chairs" :key="chair.id" :class="chair.class" >
            <!-- <div class="stool-back"></div> -->
            <div class="stool-seat"><div>{{ chair.id }}</div></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SocketIO from 'socket.io-client'
export default {
  name: 'Tables',
  data() {
    return {
      tables: [],
      chairMeals: [],
    }
  },
  computed: {
    totalMeat() {
     return this.tables.map(table => {
       return table.chairs.filter(item => item.chairMeal && item.chairMeal.meal.slug === 'meso').length
     }).reduce((a, b) => a + b, 0)
    },
    totalFish() {
      return this.tables.map(table => {
        return table.chairs.filter(item => item.chairMeal && item.chairMeal.meal.slug === 'riba').length
      }).reduce((a, b) => a + b, 0)
    },
    totalVegan() {
      return this.tables.map(table => {
        return table.chairs.filter(item => item.chairMeal && item.chairMeal.meal.slug === 'vegan').length
      }).reduce((a, b) => a + b, 0)

    },
    totalNon() {
      return this.tables.map(table => {
        return table.chairs.filter(item => !item.chairMeal).length
      }).reduce((a, b) => a + b, 0)

    }
  },
  mounted() {
    this.fetchTables()

    const socket = SocketIO('https://api.amchamgala.com', {
      cors: {
        origin: "https://amchamgala.com",
        methods: ["GET", "POST"],
        allowedHeaders: ["my-custom-header"],
        credentials: true
      }
    })
    socket.on('meal_update', (data) => {

      this.axios.get('/tables').then(res => {
        this.tables = res.data


        this.tables.forEach(table => {

          table.totalMeat = table.chairs.filter(item => item.chairMeal && item.chairMeal.meal.slug === 'meso').length
          table.totalFish = table.chairs.filter(item => item.chairMeal && item.chairMeal.meal.slug === 'riba').length
          table.totalVegan = table.chairs.filter(item => item.chairMeal && item.chairMeal.meal.slug === 'vegan').length

          table.chairs.map(chair => {

            if(chair.chairMeal && chair.chairMeal.chair_id === data.chair.id) {
              chair.chairMeal = data.chair.chairMeal
              const existingMeal = this.chairMeals.filter(item => item.chairMeal && item.chairMeal.chair_id === data.chair.id).map(item =>  item.chairMeal = data.chair.chairMeal)
              console.log(existingMeal)
            }

            if(chair.chairMeal && chair.chairMeal.meal.slug === 'meso') {
              chair.class = 'stool red'
            }

            if(chair.chairMeal && chair.chairMeal.meal.slug === 'riba') {
              chair.class = 'stool orange'
            }
            if(chair.chairMeal && chair.chairMeal.meal.slug === 'vegan') {
              chair.class = 'stool green'
            }
            if(!chair.chairMeal) {
              chair.class = 'stool gray'
            }

          })
        })

      })
    });
  },
  methods: {
    fetchTables() {
      this.axios.get('/tables').then(res => {
        this.tables = res.data

        res.data.forEach(table => {
        //  console.log(table.chairs, 'table.chairs')
          table.totalMeat = table.chairs.filter(item => item.chairMeal && item.chairMeal.meal.slug === 'meso').length
          table.totalFish = table.chairs.filter(item => item.chairMeal && item.chairMeal.meal.slug === 'riba').length
          table.totalVegan = table.chairs.filter(item => item.chairMeal && item.chairMeal.meal.slug === 'vegan').length

          table.chairs.map(chair => {

            if(chair.chairMeal && chair.chairMeal.meal.slug === 'meso') {
              chair.class = 'stool red'
            }

            if(chair.chairMeal && chair.chairMeal.meal.slug === 'riba') {
              chair.class = 'stool orange'
            }
            if(chair.chairMeal && chair.chairMeal.meal.slug === 'vegan') {
              chair.class = 'stool green'
            }
            if(!chair.chairMeal) {
              chair.class = 'stool gray'
            }

            this.chairMeals.push(chair)

          })
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  div {
    .nav-info {
      background: #070B4A;
      height: 75px;
      display: flex;
      flex-direction: row;
      .heart {
        left: 30px;
        top: 20px;
        position: absolute;
        width: 100px;
      }

      .info {
        margin: 0 auto;
        margin-top: 15px;
        display: flex;
        flex-direction: row;
        height: 47.06px;
        font-family: 'Roboto', sans-serif;

        .meat {
          background: #FF5D69;
          border: 1px solid #000000;
          box-sizing: border-box;
          border-radius: 7px;
          width: 166px;
          font-size: 26px;
          color: #FFFFFF;
          text-align: center;
          display: flex;
          column-gap: 4px;
          column-gap: 4px;
          justify-content: center;
          align-items: center;
          margin-left: 26px;
          position: relative;
          // span {
          //   margin-top:-95px;
          //   position: absolute;
          // }
        }

        .fish {
          background: #FFAE35;
          border: 1px solid #000000;
          box-sizing: border-box;
          border-radius: 7px;
          width: 166px;
          font-size: 26px;
          color: #FFFFFF;
          text-align: center;
          display: flex;
          column-gap: 4px;
          column-gap: '4px';
          justify-content: center;
          align-items: center;
          margin-left: 26px;
          position: relative;
          // span {
          //   margin-top:-95px;
          //   position: absolute;
          // }
        }


        .vegan {
          background: #87D724;
          border: 1px solid #000000;
          box-sizing: border-box;
          border-radius: 7px;
          width: 166px;
          font-size: 26px;
          color: #FFFFFF;
          text-align: center;
          display: flex;
          column-gap: 4px;
          column-gap: '4px';
          justify-content: center;
          align-items: center;
          margin-left: 26px;
          position: relative;
          // span {
          //   margin-top:-95px;
          //   position: absolute;
          // }
        }
        .non {
          background: #B4B4B4;
          border: 1px solid #000000;
          box-sizing: border-box;
          border-radius: 7px;
          padding-left: 10px;
          padding-right: 10px;
          width: fit-content;
          font-size: 26px;
          color: #FFFFFF;
          text-align: center;
          display: flex;
          column-gap: 4px;
          column-gap: '4px';
          justify-content: center;
          align-items: center;
          margin-left: 26px;
          position: relative;
          // span {
          //   margin-top:-95px;
          //   position: absolute;
          // }
        }
      }
    }

  }
  body,
  html {
    background-color: #fff;
    margin: 0;
    padding: 0;
    height: 100%;
    overflow: hidden;
  }

/*  iframe {
    border: none;
    height: 100%;
    width: 100%;
  }*/

  .hall {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .table-wrapper {
    width: 220px;
    height: 200px;
    position: relative;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .table {
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;;
    width: 100px;
    height: 100px;
    background-color: #ffdbbc;
    border: solid 1px #5e5e5e;
    border-radius: 100px;
    text-align: center;
  }

  .table h5 {
    margin: 4px 0 2px 0;
    font-size: 18px;
  }

  .table span {
    display: block;
    margin: 2px 0;
    width: 60px;
    margin-left: 20px;
  }

  .table .meat {
    background-color: #ff838c;
    font-weight: bold;
  }

  .table .fish {
    background-color: #ffc670;
    font-weight: bold;
  }

  .table .vegan {
    background-color: #c5f984;
    font-weight: bold;
  }

  // .stool-seat {
  //   position: absolute;
  //   z-index: 9999;
  // }

  .stool:nth-child(2) {
    position: absolute;
    top: 5px;
    left: 95px;
  }

  .stool:nth-child(3) {
    position: absolute;
    top: 25px;
    left: 145px;
  }

  .stool:nth-child(4) {
    position: absolute;
    top: 75px;
    left: 165px;
  }

  .stool:nth-child(5) {
    position: absolute;
    top: 125px;
    left: 155px;
  }

  .stool:nth-child(6) {
    position: absolute;
    top: 155px;
    left: 115px;
  }

  .stool:nth-child(7) {
    position: absolute;
    top: 155px;
    left: 65px;
  }

  .stool:nth-child(8) {
    position: absolute;
    top: 120px;
    left: 25px;
  }

  .stool:nth-child(9) {
    position: absolute;
    top: 70px;
    left: 15px;
  }

  .stool:nth-child(10) {
    position: absolute;
    top: 20px;
    left: 40px;
  }

  .stool-seat {
    width: 32px;
    height: 32px;
    padding: 3px;
    border-radius: 32px;
    border: solid 1px #5e5e5e;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
  }

  .stool-back {
    width: 28px;
    height: 4px;
    margin: 0 2px 4px 2px;
    border-radius: 2px;
    border: solid 1px #5e5e5e;
  }

  .green .stool-back {
    background-color: #c5f984;
  }

  .green .stool-seat {
    background-color: #c5f984;
  }

  .orange .stool-back {
    background-color: #ffc670;
  }

  .orange .stool-seat {
    background-color: #ffc670;
  }

  .red .stool-back {
    background-color: #ff838c;
  }

  .red .stool-seat {
    background-color: #ff838c;
  }

  .gray .stool-seat {
    background-color: #B4B4B4;
  }

  .gray .stool-back {
    background-color: #B4B4B4;
  }

</style>