<template>
  <div>
    <img class="main-img" alt="Bottom image" src="./../assets/main_image.jpg">
    <div class="shape"></div>
    <div class="top">
      <h1>Dobrodošli / Welcome</h1>
    </div>
    <div class="main">
      <div class="relative">
        <h2>Odaberite stolicu / Select your seat</h2>
        <input @click="showDropdown = !showDropdown;" 
         placeholder="Broj stolice / Seat Number" v-on:input="selectChair()" v-model="inputChair" />
        <ul v-if="showDropdown">
          <li @click="setSelectedChair(chair)" :style="selectedChair && selectedChair.id === chair.id ? 'background:#070B4A;color:#ffffff;font-family:Gilroy-Bold' : ''" v-for="chair in chairs" :key="chair.id">
                {{chair.id}}
          </li>
        </ul>
        <button :disabled="!selectedChair.id" :style="!selectedChair.id ? 'opacity: 0.5;' : ''" class="margin-bottom" @click.prevent="$router.push({path: `/izmjena/stolica/${selectedChair.id}`})">Potvrdi / Confirm</button>
      </div>
    </div>
    <img class="bottom-img" style="width:90%; margin: 20px;" alt="Bottom image" src="./../assets/partneri.png">

  </div>
</template>

<script>
export default {
  name: 'MainEdit',
  data() {
    return {
      chairs: [],
      chairsOriginal: [],
      selectedChair: {
        id: null,
        text: 'Stolica broj '
      },
      inputChair: undefined,
      showDropdown: false
    }
  },

  created() {
    this.fetchChairs();

  },

  methods: {
    fetchChairs() {
      this.axios.get('/chairs').then(res => {
        this.chairs = res.data;
        this.chairsOriginal = res.data;
      })
    },

    setSelectedChair(chair) {
      this.selectedChair.text = chair.id;
      this.selectedChair.id = chair.id;
      this.showDropdown = false;
      this.inputChair =  this.selectedChair.text;
    },


    submitChairSelection() {
    let chairId=  parseInt(this.selectedChair.id, 10);
     this.$router.push({path: `/stolica/${chairId}`})
    },

    selectChair() {
      let searchItem = this.inputChair;
      let chairsArray = this.chairsOriginal.filter(x => x.id.toString().indexOf(searchItem) >-1);
      this.chairs = chairsArray;

    }

  },
  mounted() {
    localStorage.clear();
  }
}
</script>