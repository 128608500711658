<template>
  <div>
    <img  class="main-img" alt="Bottom image" src="./../assets/main_image.jpg">
    <div>
      <div class="shape"></div>
      <div class="top">
        <h1>Dobrodošli / Welcome</h1>
      </div>
      <div class="main">
        <div>
          <h2>Vaše mjesto / Your seat</h2>
          <div class="icons">
            <div class="rectangle"> <img alt="table" src="./../assets/table.svg" /></div>
            <div class="rectangle"> <img alt="chair" src="./../assets/chair.svg" /></div>
          </div>
          <div class="tables">
            <span >Sto / Table <br/> {{ tableId }}</span>
            <span >Stolica / Chair <br/> {{ chairId }}</span>
          </div>
          <div class="line" />
          <h2>Hladno predjelo / Cold appetizer</h2>
          <h3>Caprese salata sa svježim bosiljkom i pestom / Caprese salad with fresh basil and pesto</h3>
          <div class="line" />
          <h2>Glavno jelo / Main Course</h2>
          <p v-if="!showSelectedItem">Odaberite vaše glavno jelo / Please select your Main Course</p>
          <button @click="$router.push({name: 'MainCourse', params: {id: chairId}})" v-if="!showSelectedItem" >Odaberite / Select</button>
          <div v-else>
            <h3 v-if="chairMeal !== null && chairMeal !== undefined">{{ chairMeal.meal.name }}</h3>
            <p v-if="chairMeal !== null && chairMeal !== undefined">{{ chairMeal.meal.description }}</p>
          </div>
          <div class="line mt-24"></div>
          <h2>Salata / Salad</h2>
          <h3>Quinoa salata sa 3 vrste paprike / Quinoa salad with 3 types of peppers</h3>
          <div class="line mt-24"></div>
          <h2>Dezert / Dessert</h2>
          <h3>Mrvica Tatli kolač (kadaif tufaija i kadaif mix) / Crumb tatli cake (Bosnian traditional cake)</h3>
          <div class="line mt-24"></div>
          <h2>Hladno posluženje (servira se nakon večere) / Cold Food (served after dinner)</h2>
          <h3>Voće, sir i apetisani / Fruit, cheese and appetizers</h3>
          <div class="line mt-24"></div>
        </div>
      </div>
    </div>
    <img class="bottom-img" style="width:90%; margin: 20px;" alt="Bottom image" src="./../assets/partneri.png">
  </div>

</template>

<script>
export default {
  name: 'UserChair',
  data() {
    return {
      showModal: false,
      showSelectedItem: false,
      chairMeal: null,
      order: null,
      tableId: null,
    }
  },
  mounted() {
    this.axios.get(`/chairs/${this.$route.params.id}`).then(res => {
      this.tableId = res.data.table.id
    })
    this.order = JSON.parse(localStorage.getItem('order'))
    if(this.$route.query.meni) {

      console.log('it is inside meni');

      this.showSelectedItem = true
      this.createMeal()
    }
  },
  methods: {
    fetchMeals() {
      this.axios.get('/meals').then(res => {
        this.meals = res.data
      })
    },
    createMeal() {
      console.log('cretae meal is called');
      this.axios.post(`/chairs/${this.chairId}/meal?meal_id=${this.order.id}`).then(res => {
        this.chairMeal = res.data.chairMeal
        localStorage.setItem('chairMeal', JSON.stringify(this.chairMeal))
      })
    },
  },
  computed: {
    chairId() {
      return this.$route.params.id
    },

  }
}
</script>
