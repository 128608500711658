<template>
  <div>
    <img class="main-img" alt="Bottom image" src="./../assets/main_image.jpg">
    <div>
      <div class="shape"></div>
      <div class="top">
        <h1>Dobrodošli / Welcome</h1>
      </div>
      <div class="main">
        <div>
          <h2>Mjesto / Seat</h2>
          <div class="icons">
            <div class="rectangle"> <img alt="table" src="./../assets/table.svg" /></div>
            <div class="rectangle"> <img alt="chair" src="./../assets/chair.svg" /></div>
          </div>
          <div class="tables">
            <span >Sto / Table <br/> {{ tableId }}</span>
            <span >Stolica / Chair <br/> {{ chairId }}</span>
          </div>
          <div class="line" />
          <h2>Toplo predjelo / Hot appetizer</h2>
          <h3>Rižoto sa grana padanom, rukolom i sušenom rajčicom / Risotto with grana padano, rocket and sun-dried tomatoes</h3>
          <div class="line"></div>
          <h2>Glavno jelo / Main Course</h2>
          <p v-if="!showSelectedItem">Odaberite glavno jelo</p>
          <button @click="$router.push({name: 'MainCourseEdit', params: {id: chairId}})" v-if="!showSelectedItem" >Odaberite glavno jelo</button>
          <div v-else>
            <h3>{{ chairMeal.meal.name }}</h3>
            <p>{{ chairMeal.meal.description }}</p>
          </div>
          <div class="line mt-24"></div>
          <h2>Salata / Salad</h2>
          <h3>Quinoa salata sa 3 vrste paprike / Quinoa salad with 3 types of peppers</h3>
          <div class="line mt-24"></div>
          <h2>Dezert / Dessert</h2>
          <h3>Mrvica Tatli kolač (kadaif tufaija i kadaif mix) / Crumb tatli cake (Bosnian traditional cake)</h3>
          <div class="line mt-24"></div>
          <h2>Hladno posluženje (servira se nakon večere) / Cold Food (served after dinner)</h2>
          <h3>Voće, sir i apetisani / Fruit, cheese and appetizers</h3>
          <div class="line mt-24"></div>
        </div>
      </div>
    </div>
    <img class="bottom-img" style="width:90%; margin: 20px;" alt="Bottom image" src="./../assets/partneri.png">
  </div>
</template>

<script>

export default {
  name: 'UserChairEdit',
  data() {
    return {
      showModal: false,
      showSelectedItem: false,
      order: null,
      tableId: null,
    }
  },
  mounted() {
    this.axios.get(`/chairs/${this.$route.params.id}`).then(res => {
      this.tableId = res.data.table.id
    })


    if(this.$route.query.meni) {
      this.showSelectedItem = true
      this.createMeal()
    }
  },
  methods: {
    fetchMeals() {
      this.axios.get('/meals').then(res => {
        this.meals = res.data
      })
    },
    createMeal() {
      this.axios.post(`/chairs/${this.chairId}/meal?meal_id=${this.order.id}`).then(res => {
        this.chairMeal = res.data.chairMeal
        this.sockets.subscribe('meal_update', (data) => {
          localStorage.setItem('orderData', JSON.stringify(data))
        });
      })
    },
  },
  computed: {
    chairId() {
      return this.$route.params.id
    },
  }

}
</script>
