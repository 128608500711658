<template>
  <div id="main-course">
    <div v-if="!confirmed" class="main">
      <div class="main-course">
        <div class="fixed">
          <div class="info">
            <img style="width: 24px; height: 24px;" @click="$router.push({name: 'UserChair', props: {id: chairId}})" alt="arrow-left" src="./../assets/arrow-left.svg" width="24px" height="24px"/>
            <h4>Glavno jelo / Main Course</h4>
          </div>
          <div class="line"></div>
          <p>Odaberite glavno jelo / Please select Main Course</p>
        </div>
        <div class="list">
          <div class="meal-box" v-for="(meal, index) in meals" :key="index">
            <div :class="selectedMeal && selectedMeal.name === meal.name ? 'meal-selected' : ''" @click="selectedMeal = meal" class="meal-list">
              <div>
                <h2 :style="selectedMeal && selectedMeal.name === meal.name ? 'color:#070B4A' : ''">{{ meal.name }}</h2>
                <img v-if="selectedMeal && selectedMeal.name === meal.name " alt="selected" src="./../assets/checked.svg" />
              </div>
              <p>{{ meal.description }}</p>
            </div>
            <h2 :style="(index + 1) === meals.length ? 'display: none;' : ''" class="or">ili / or</h2>
          </div>
        </div>
        <button :disabled="!selectedMeal" @click="showModal = true" :style="!selectedMeal ? 'opacity: 0.5;' : ''">Potvrdi / Confirm</button>
      </div>
      <modal v-if="showModal" @confirm="proceed" @close="showModal = false">
        <template v-slot:body>
          <div>
            <p>Odabrano jelo se ne može promijeniti nakon prvog odabira</p>
            <p>Selected main course can't be changed later</p>
            <span class="modal-text">{{ selectedMeal.name }}</span>
          </div>
        </template>
      </modal>
    </div>
  </div>
</template>

<script>
import Modal from './../components/Modal.vue'
export default {
  name: 'MainCourse',
  components: {Modal},
  data() {
    return {
      showModal: false,
      selectedMeal: null,
      confirmed: false,
      meals: []
    }
  },
  mounted() {
    document.getElementById('main-course').scrollIntoView();
    this.fetchMeals()
  },
  computed: {
    chairId() {
      return this.$route.params.id
    },
  },
  created() {
    if(localStorage.getItem('chairMeal')) {
      const chairMeal = JSON.parse(localStorage.getItem('chairMeal'))
      const url = `/stolica/${chairMeal.chair_id}?meni=${chairMeal.meal_id}`
      window.location = window.location.href.substr(0, window.location.href.indexOf(this.$route.fullPath)) + url;
    }
  },
  methods: {
    proceed() {
      this.showModal = false;
      this.confirmed = true;
      window.location = window.location.href.substr(0, window.location.href.indexOf('/vecera')) + '?meni='+this.selectedMeal.id;
      localStorage.setItem('order', JSON.stringify(this.selectedMeal))
    },
    fetchMeals() {
      this.axios.get('/meals').then(res => {
        this.meals = res.data
      })
    }
  }
}
</script>
