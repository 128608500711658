import { createApp } from 'vue'
import App from './App.vue'
import "./styles/main.scss";
import router from './router.js';
import axios from 'axios'
import VueAxios from 'vue-axios'
/*import VueSocketIO from 'vue-3-socket.io'
import SocketIO from 'socket.io-client'*/
import Toaster from '@meforma/vue-toaster'

const app = createApp(App)

//prod
axios.defaults.baseURL = 'https://api.amchamgala.com/api'
//local
//axios.defaults.baseURL = 'http://localhost:8080/api';
//const socketConnection = SocketIO('http://localhost:8080');

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

/*
app.use(new VueSocketIO({
    debug: true,
    connection:socketConnection

}))*/

app.use(router)
app.use(VueAxios, axios)
app.provide('axios', app.config.globalProperties.axios)

app.use(Toaster, {
    position: 'top-right'
})

app.mount('#app')

