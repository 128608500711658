<template>
  <div id="main-course">
    <div v-if="!confirmed" class="main">
      <div class="main-course">
        <div class="fixed">
          <div class="info">
            <img @click="window.location.href='/izmjena/stolica/'+chairId" alt="arrow-left" src="./../assets/arrow-left.svg" />
            <h4>Glavno jelo / Main Course</h4>
          </div>
          <div class="line"></div>
          <p>Promijenite glavno jelo / Update Main Course</p>
        </div>
        <div class="list">
          <div class="meal-box" v-for="(meal, index) in meals" :key="index">
            <div :class="selectedMeal && selectedMeal.name === meal.name ? 'meal-selected' : ''" @click="selectedMeal = meal" class="meal-list">
              <div>
                <h2 :style="selectedMeal && selectedMeal.name === meal.name ? 'color:#070B4A' : ''">{{ meal.name }}</h2>
                <img v-if="selectedMeal && selectedMeal.name === meal.name " alt="selected" src="./../assets/checked.svg" />
              </div>
              <p>{{ meal.description }}</p>
            </div>
            <h2 :style="(index + 1) === meals.length ? 'display: none;' : ''" class="or">ili / or</h2>
          </div>
        </div>
        <button :disabled="!selectedMeal" @click="showModal = true" :style="!selectedMeal ? 'opacity: 0.5;' : ''">Potvrdi / Confirm</button>
      </div>
      <modal v-if="showModal" @confirm="proceed" @close="showModal = false">
        <template v-slot:body>
          <div>
            <span class="modal-text">{{ selectedMeal.name }}</span>
          </div>
        </template>
      </modal>
    </div>
  </div>
</template>

<script>
import Modal from './../components/Modal.vue'
export default {
  name: 'MainCourseEdit',
  components: {Modal},
  data() {
    return {
      showModal: false,
      selectedMeal: null,
      confirmed: false,
      meals: [],
      updated: false,
      chairMeal: null,
    }
  },
  mounted() {
    document.getElementById('main-course').scrollIntoView();
    this.fetchMeals()
  },
  computed: {
    chairId() {
      return this.$route.params.id
    },
  },
  methods: {
    proceed() {
      this.confirmed = true;
      this.createMeal()
    },
    createMeal() {
      this.axios.get(`/chairs/${this.chairId}`).then(res => {
        console.log(res.data, 'res.data')
        if(! res.data.chairMeal) {
          this.axios.post(`/chairs/${this.chairId}/meal?meal_id=${this.selectedMeal.id}`).then(res => {
            this.chairMeal = res.data.chairMeal
          })
        } else {
          this.axios.put(`/chairs/${this.chairId}/meal?meal_id=${this.selectedMeal.id}`).then(() => {
            this.updated = true


            
          })
        }
        this.$router.push({name: 'MainEdit'});
        this.$toast.success('Jelo je uspješno spašeno!')
      })

    },
    fetchMeals() {
      this.axios.get('/meals').then(res => {
        this.meals = res.data
      })
    }
  }
}
</script>
