import { createWebHistory, createRouter } from "vue-router";
import Main from "@/pages/Main.vue";
import UserChair from "@/pages/UserChair.vue";
import MainCourse from "@/pages/MainCourse.vue";
import MainEdit from "@/pages/MainEdit.vue";
import UserChairEdit from "@/pages/UserChairEdit.vue";
import MainCourseEdit from "@/pages/MainCourseEdit.vue";
import Tables from "@/pages/Tables.vue";


const routes = [
    {
        path: "/",
        name: "Main",
        component: Main,
    },
    {
        path: "/stolica/:id",
        name: "UserChair",
        component: UserChair,
        props: true,
    },
    {
        path: "/stolica/:id/vecera",
        name: "MainCourse",
        component: MainCourse,
    },

    {
        path: "/izmjena",
        name: "MainEdit",
        component: MainEdit,
    },
    {
        path: "/izmjena/stolica/:id",
        name: "UserChairEdit",
        component: UserChairEdit,
        props: true,
    },
    {
        path: "/izmjena/stolica/:id/vecera",
        name: "MainCourseEdit",
        component: MainCourseEdit,
    },
    {
        path: "/narudzbe",
        name: "Tables",
        component: Tables,
    },

];

const router = createRouter({
    history: createWebHistory(),
    routes,
});



export default router;