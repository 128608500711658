<template>
  <div>
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'App',

}
</script>

<style lang="scss" scoped>
#app {
  font-family: 'gilroy-heavy',sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

@mixin for-phone-only {
  @media (max-width: 599px) {
    @content;
  }
}




</style>
